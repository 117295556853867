import React from 'react'
import BottomPagination from './BottomPagination'
function Dormitoryinfo() {
  return (
    <div>
    <div>
        <div className='flex justify-center items-center'>
                <div className='text-4xl text-center w-[70%]'>
                    Its easy to get started on Hosteldevta
                </div>
        </div>
    </div>
    <div>
    <div className='mt-[3rem]'>
<div  className="w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
<div className="bg-black h-1 rounded-full w-[45%]" ></div>
</div>
    </div>


</div>       

<BottomPagination/>

</div>
  )
}

export default Dormitoryinfo